import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserLanding from './components/UserLanding/UserLanding';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import BedOccupancy from './components/BedOccupancy/BedOccupancy';
import CheckInProcedure from './components/CheckIn/CheckInProcedure';
import SharingProcedure from './components/SharingProcedure/SharingProcedure';
import CheckOutProcedure from './components/CheckOutProcedure/CheckOutProcedure';
// import OnboardUser from './components/OnboardUser/OnboardUser';
import ToDoList from './components/ToDoList/ToDoList';
import AdminRegistration from './components/AdminRegistration/AdminRegistration';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/" element={<UserLanding />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/occupancy" element={<BedOccupancy />} />
          <Route path="/checkin" element={<CheckInProcedure />} />
          <Route path="/sharing" element={<SharingProcedure />} />
          <Route path="/checkout" element={<CheckOutProcedure />} />
          {/* <Route path="/onboard" element={<OnboardUser />} /> */}
          <Route path="/todo" element={<ToDoList />} />
          <Route path="/adminregistration" element={<AdminRegistration />} />

          {/* Additional routes can be added here as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
