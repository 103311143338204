import React, { useState } from "react";
import { TextField, Button, Typography, Grid, Snackbar, Alert } from "@mui/material";
import AWS from "aws-sdk";
import "./AdminRegistration.css";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const AdminRegistration = () => {
  const [blockName, setBlockName] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [numberOfBeds, setNumberOfBeds] = useState("");
  const [bedStatus, setBedStatus] = useState([]);
  const [existingData, setExistingData] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: "", severity: "info" });

  // Configure AWS
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const dynamoDb = new AWS.DynamoDB();

  const showNotification = (message, severity) => {
    setNotification({ open: true, message, severity });
  };

  const handleCloseNotification = () => {
    setNotification({ open: false, message: "", severity: "info" });
  };

  const handleReset = () => {
    setNumberOfBeds("");
    setBedStatus([]);
    setExistingData(false);
  };

  const checkExistingData = async () => {
    const formattedBlockName = blockName.trim().toUpperCase();
    const formattedRoomNumber = roomNumber.trim();

    if (!formattedBlockName || !formattedRoomNumber) {
      showNotification("Please fill in Block Name and Room Number", "error");
      return;
    }

    const params = {
      TableName: process.env.REACT_APP_AWS_DEFAULT_REG_TABLE,
      KeyConditionExpression: "blockName = :blockName and roomNumber = :roomNumber",
      ExpressionAttributeValues: {
        ":blockName": { S: formattedBlockName },
        ":roomNumber": { S: formattedRoomNumber },
      },
    };

    try {
      const result = await dynamoDb.query(params).promise();
      if (result.Items.length > 0) {
        const existingBedStatus = result.Items[0]["bed-status"].M;
        const beds = Object.entries(existingBedStatus).map(([key, value]) => ({
          index: key,
          status: value.S,
        }));
        setBedStatus(beds);
        setNumberOfBeds(beds.length.toString());
        setExistingData(true);
        showNotification("Room already exists. Bed statuses loaded.", "success");
      } else {
        handleReset(); // Reset data if no existing room is found
        showNotification("No existing data found for this room.", "info");
      }
    } catch (error) {
      console.error("Error querying data: ", error);
      showNotification("Failed to check existing data.", "error");
    }
  };

  const handleGenerateBeds = () => {
    if (!numberOfBeds || isNaN(numberOfBeds) || numberOfBeds <= 0) {
      showNotification("Please enter a valid number of beds.", "error");
      return;
    }

    const newNumberOfBeds = Number(numberOfBeds);
    const updatedBeds = [...Array(newNumberOfBeds)].map((_, index) => ({
      index: (index + 1).toString(),
      status: "vacant",
    }));

    setBedStatus(updatedBeds);
    showNotification(`${numberOfBeds} beds generated successfully.`, "success");
  };

  const handleAddBed = () => {
    const newBedIndex = bedStatus.length + 1;
    const updatedBeds = [...bedStatus, { index: newBedIndex.toString(), status: "vacant" }];
    setBedStatus(updatedBeds);
    setNumberOfBeds(updatedBeds.length.toString());
    showNotification("Bed added successfully.", "success");
  };

  const handleRemoveBed = () => {
    if (bedStatus.length === 0) {
      showNotification("No beds to remove.", "error");
      return;
    }
    const updatedBeds = bedStatus.slice(0, -1);
    setBedStatus(updatedBeds);
    setNumberOfBeds(updatedBeds.length.toString());
    showNotification("Bed removed successfully.", "success");
  };

  const handleSubmit = async () => {
    const formattedBlockName = blockName.trim().toUpperCase();
    const formattedRoomNumber = roomNumber.trim();

    if (!formattedBlockName || !formattedRoomNumber || bedStatus.length === 0) {
      showNotification("Please fill all fields and generate beds.", "error");
      return;
    }

    const dynamoData = {
      blockName: { S: formattedBlockName },
      roomNumber: { S: formattedRoomNumber },
      "bed-status": {
        M: bedStatus.reduce((acc, bed) => {
          acc[bed.index] = { S: bed.status };
          return acc;
        }, {}),
      },
    };

    const params = {
      TableName: process.env.REACT_APP_AWS_DEFAULT_REG_TABLE,
      Item: dynamoData,
    };

    try {
      await dynamoDb.putItem(params).promise();
      showNotification("Data submitted successfully!", "success");
      setBlockName("");
      setRoomNumber("");
      setNumberOfBeds("");
      setBedStatus([]);
      setExistingData(false);
    } catch (error) {
      console.error("Error submitting data: ", error);
      showNotification("Failed to submit data.", "error");
    }
  };

  const navigate = useNavigate();

  return (
    <div className="admin-container">
      <div style={{ width: "100%", maxWidth: "430px" }}>
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          sx={{
            mb: 3,
            backgroundColor: "#029202",
            color: "#FFFFFF",
            "&:hover": { backgroundColor: "#029202" },
            fontSize: "clamp(14px, 1.5vw, 14px)",
            borderRadius: "10px",
            padding: "8px 16px",
            textTransform: "none",
          }}
        >
          Back
        </Button>
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}>
        <img
          src={logo}
          alt="Logo"
          style={{
            width: "140px",
            height: "140px",
          }}
        />
      </div>
      <Typography variant="h6" className="form-title">
        Room Registration
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Block Name"
            value={blockName}
            onChange={(e) => {
              setBlockName(e.target.value.toUpperCase());
              handleReset();
            }}
            fullWidth
            className="input-field"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Room Number"
            value={roomNumber}
            onChange={(e) => {
              setRoomNumber(e.target.value);
              handleReset();
            }}
            fullWidth
            className="input-field"
          />
          <Button
            variant="contained"
            onClick={checkExistingData}
            disabled={!blockName.trim() || !roomNumber.trim()}
            className="check-button"
          >
            See Existing Beds
          </Button>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Number of Beds"
            type="number"
            value={numberOfBeds}
            onChange={(e) => setNumberOfBeds(e.target.value)}
            fullWidth
            className="input-field"
          />
          <Button
            variant="contained"
            onClick={handleGenerateBeds}
            disabled={existingData || !numberOfBeds || numberOfBeds <= 0}
            className="generate-button"
          >
            Generate Beds
          </Button>
        </Grid>

        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Button
              variant="contained"
              onClick={handleAddBed}
              className="increment-button"
            >
              + Add Bed
            </Button>
            <Button
              variant="contained"
              onClick={handleRemoveBed}
              disabled={bedStatus.length === 0}
              className="decrement-button"
            >
              - Remove Bed
            </Button>
          </div>
        </Grid>

        {bedStatus.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="subtitle1">Bed Status</Typography>
            <Grid container spacing={2}>
              {bedStatus.map((bed, index) => (
                <Grid item xs={3} key={index}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      const updatedStatus = [...bedStatus];
                      updatedStatus[index].status =
                        updatedStatus[index].status === "occupied"
                          ? "vacant"
                          : "occupied";
                      setBedStatus(updatedStatus);
                    }}
                    className={`bed-status-button ${
                      bed.status === "occupied" ? "occupied" : "vacant"
                    }`}
                  >
                    Bed {bed.index}: {bed.status}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={
              !blockName.trim() ||
              !roomNumber.trim() ||
              bedStatus.length === 0 ||
              bedStatus.length !== parseInt(numberOfBeds)
            }
            className="submit-button"
          >
            Submit to Database
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={handleCloseNotification}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AdminRegistration;
