import React from 'react';
import { Container, Grid, Button, Box, Typography } from '@mui/material';
import './UserLanding.css';
import logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import awsExports from '../../aws-exports';
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { signOut } from 'aws-amplify/auth';

Amplify.configure(awsExports);

function UserLanding() {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <>
      <Button 
        className="top-right-signout"
        onClick={handleSignOut}
      >
        Sign Out
      </Button>
      
      <Container className="landing-container">
        <Box className="logo-section">
          <img src={logo} alt="Logo" className="logo" />
          <Typography className="logo-text">
            O thou that hearest prayer, unto thee shall all flesh come ...
          </Typography>
        </Box>

        <Box className="button-group">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6}>
              <Button className="action-button" onClick={() => navigate('/checkin')}>
                Check-In
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button className="action-button" onClick={() => navigate('/checkout')}>
                Check-Out
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button className="admin-button" onClick={() => navigate('/admin')}>
                Admin
              </Button>
            </Grid>
          </Grid>
          
          <Box className="divider"></Box>

          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6}>
              <Button className="share-button" onClick={() => navigate('/sharing')}>
                Share
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button className="onboard-button" onClick={() => navigate('/todo')}>
                Rem. Notes
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Typography className="footer-text">
          © {new Date().getFullYear()} H.G.P.M
        </Typography>
      </Container>
    </>
  );
}

export default withAuthenticator(UserLanding);