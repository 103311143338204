import React, { useState } from 'react';
import {
  Container,
  Button,
  TextField,
  MenuItem,
  Alert,
  Typography,
  Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

const CheckInProcedure = () => {
  // eslint-disable-next-line
  const [hostel, setHostel] = useState('');
  const [block, setBlock] = useState('');
  const [bed, setBed] = useState('');
  const [isOccupied, setIsOccupied] = useState(false);
  const [adults, setAdults] = useState(1);
  const [kids, setKids] = useState(0);
  const [clergy, setClergy] = useState('Yes');
  const [checkOutDate, setCheckOutDate] = useState('');

  const beds = [
    { id: '1', status: 'Available' },
    { id: '2', status: 'Occupied' },
    { id: '3', status: 'Available' },
    { id: '4', status: 'Occupied' },
  ];

  const handleBedChange = (e) => {
    const selectedBed = e.target.value;
    setBed(selectedBed);
    const isOccupied = beds.find((b) => b.id === selectedBed && b.status === 'Occupied');
    setIsOccupied(!!isOccupied);
  };

  const navigate = useNavigate();

  return (
    <Container 
      maxWidth={false} 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#ffffff',
        padding: '20px',
        '& .MuiTextField-root': {
          width: '100%',
        }
      }}
    >
      <div style={{ width: '100%', maxWidth: '430px' }}>
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          sx={{
            mb: 3,
            backgroundColor: '#029202',
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#029202' },
            fontSize: 'clamp(14px, 1.5vw, 14px)',
            borderRadius: '10px',
            padding: '8px 16px',
            textTransform: 'none',
          }}
        >
          Back
        </Button>

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
          <img
            src={logo}
            alt="Logo"
            style={{
              width: '140px',
              height: '140px',
            }}
          />
        </div>

        <Typography 
          variant="h6" 
          sx={{ 
            textAlign: 'center', 
            marginBottom: '15px', 
            color: '#029202',
            fontSize: '1.25rem',
            fontWeight: 500
          }}
        >
          Check-In Procedure
        </Typography>

        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <TextField
              label="Hostel"
              select
              value={hostel}
              onChange={(e) => setHostel(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value="KingsA">Kings A</MenuItem>
              <MenuItem value="KingsB">Kings B</MenuItem>
              <MenuItem value="KingsC">Kings C</MenuItem>
              <MenuItem value="KingsD">Kings D</MenuItem>
            </TextField>
          </Grid> */}

          <Grid item xs={12}>
            <TextField
              label="Block"
              select
              value={block}
              onChange={(e) => setBlock(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value="Block1">B1</MenuItem>
              <MenuItem value="Block2">B2</MenuItem>
              <MenuItem value="Block3">B3</MenuItem>
              <MenuItem value="Block4">B4</MenuItem>
              <MenuItem value="Block5">B5</MenuItem>
              <MenuItem value="Block6">B6</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Bed"
              select
              value={bed}
              onChange={handleBedChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            >
              {beds.map((bed) => (
                <MenuItem key={bed.id} value={bed.id}>
                  {bed.id}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {isOccupied && (
            <Grid item xs={12}>
              <Alert severity="warning">Bed already occupied</Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              label="No. of Adults"
              type="number"
              value={adults}
              onChange={(e) => setAdults(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="No. of Kids"
              type="number"
              value={kids}
              onChange={(e) => setKids(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Clergy?"
              select
              value={clergy}
              onChange={(e) => setClergy(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Check-out Date"
              type="date"
              value={checkOutDate}
              onChange={(e) => setCheckOutDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              disabled={isOccupied || !block || !bed || !adults || !clergy || !checkOutDate}
              sx={{
                mb: 3,
                backgroundColor: '#029202',
                color: '#FFFFFF',
                '&:hover': { backgroundColor: '#029202' },
                fontSize: 'clamp(14px, 1.5vw, 14px)',
                borderRadius: '10px',
                padding: '8px 16px',
                textTransform: 'none',
                minWidth: '200px'
              }}
            >
              Check-In
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default CheckInProcedure;