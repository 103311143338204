// /src/components/CheckOutProcedure.js
import React, { useState } from 'react';
import {
  Container,
  Button,
  TextField,
  MenuItem,
  Typography,
  Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

const CheckOutProcedure = () => {
  // eslint-disable-next-line
  const [hostel, setHostel] = useState('');
  const [block, setBlock] = useState('');
  const [bed, setBed] = useState('');
  const [adults, setAdults] = useState(1);
  const [kids, setKids] = useState(0);
  // eslint-disable-next-line
  const [remainingNumber, setRemainingNumber] = useState('51');
  const navigate = useNavigate();

  const beds = [
    { id: '1' },
    { id: '2' },
    { id: '3' },
    { id: '4' },
  ]; // Replace with dynamic bed data if applicable

  return (
    <Container
      maxWidth="sm"
      sx={{
        marginTop: '20px',
        padding: '20px',
        minHeight: '100vh',
        display: 'block',
      }}
    >
      <Button
        variant="contained"
        onClick={() => navigate(-1)}
        sx={{
          mb: 3,
          backgroundColor: '#029202',
          color: '#FFFFFF',
          '&:hover': { backgroundColor: '#029202' },
          fontSize: 'clamp(14px, 1.5vw, 14px)',
          borderRadius: '10px',
          padding: '8px 16px',
          textTransform: 'none',
        }}
      >
        Back
      </Button>

      <div style={{ textAlign: 'center', marginBottom: '15px' }}>
        <img src={logo} alt="Logo" style={{ width: '140px', height: '140px' }} />
      </div>

      <Typography
        variant="h6"
        sx={{
          textAlign: 'center',
          marginBottom: '15px',
          color: '#029202',
        }}
      >
        Check-Out Procedure
      </Typography>

      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={6}>
          <TextField
            label="Hostel"
            select
            value={hostel}
            onChange={(e) => setHostel(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value="KingsA">Kings A</MenuItem>
            <MenuItem value="KingsB">Kings B</MenuItem>
            <MenuItem value="KingsC">Kings C</MenuItem>
            <MenuItem value="KingsD">Kings D</MenuItem>
          </TextField>
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <TextField
            label="Block"
            select
            value={block}
            onChange={(e) => setBlock(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value="Block1">B1</MenuItem>
            <MenuItem value="Block2">B2</MenuItem>
            <MenuItem value="Block3">B3</MenuItem>
            <MenuItem value="Block4">B4</MenuItem>
            <MenuItem value="Block5">B5</MenuItem>
            <MenuItem value="Block6">B6</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Bed"
            select
            value={bed}
            onChange={(e) => setBed(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          >
            {beds.map((bed) => (
              <MenuItem key={bed.id} value={bed.id}>
                {bed.id}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="No. of Adults"
            type="number"
            value={adults}
            onChange={(e) => setAdults(Number(e.target.value))}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: 1 }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="No. of Kids"
            type="number"
            value={kids}
            onChange={(e) => setKids(Number(e.target.value))}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: 0 }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Remaining Number"
            value={remainingNumber}
            disabled
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginBottom: '15px' }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            disabled={!block || !bed || adults <= 0}
            sx={{
              mb: 3,
              backgroundColor: '#029202',
              color: '#FFFFFF',
              '&:hover': { backgroundColor: '#029202' },
              fontSize: 'clamp(14px, 1.5vw, 14px)',
              borderRadius: '10px',
              padding: '8px 16px',
              textTransform: 'none',
              display: 'block',
              width: '100%',
            }}
          >
            Check-Out
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CheckOutProcedure;
