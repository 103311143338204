import React, { useState } from 'react';
import { Grid, Button, Typography, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import './BedOccupancy.css';
import { useNavigate } from 'react-router-dom';

const mockHostelData = {
  'Hostel A': {
    blocks: {
      'A': {
        rooms: [
          { roomNumber: '101', beds: [{ id: 1, status: 'occupied' }, { id: 2, status: 'available' }, { id: 3, status: 'occupied' }, { id: 4, status: 'available' }] },
          { roomNumber: '102', beds: [{ id: 5, status: 'available' }, { id: 6, status: 'occupied' }, { id: 7, status: 'available' }, { id: 8, status: 'occupied' }] },
          { roomNumber: '103', beds: [{ id: 1, status: 'occupied' }, { id: 2, status: 'available' }, { id: 3, status: 'occupied' }, { id: 4, status: 'available' }] },
          { roomNumber: '104', beds: [{ id: 5, status: 'available' }, { id: 6, status: 'occupied' }, { id: 7, status: 'available' }, { id: 8, status: 'occupied' }] },
          { roomNumber: '105', beds: [{ id: 1, status: 'occupied' }, { id: 2, status: 'available' }, { id: 3, status: 'occupied' }, { id: 4, status: 'available' }] },
        ]
      },
      'B': {
        rooms: [
          { roomNumber: '201', beds: [{ id: 9, status: 'occupied' }, { id: 10, status: 'occupied' }, { id: 11, status: 'available' }, { id: 12, status: 'available' }] },
          { roomNumber: '202', beds: [{ id: 13, status: 'available' }, { id: 14, status: 'occupied' }, { id: 15, status: 'occupied' }, { id: 16, status: 'available' }] },
        ]
      }
    }
  },
  'Hostel B': {
    blocks: {
      'C': {
        rooms: [
          { roomNumber: '301', beds: [{ id: 17, status: 'occupied' }, { id: 18, status: 'available' }, { id: 19, status: 'occupied' }, { id: 20, status: 'available' }] },
          { roomNumber: '302', beds: [{ id: 21, status: 'available' }, { id: 22, status: 'occupied' }, { id: 23, status: 'available' }, { id: 24, status: 'occupied' }] },
        ]
      },
      'D': {
        rooms: [
          { roomNumber: '401', beds: [{ id: 25, status: 'occupied' }, { id: 26, status: 'occupied' }, { id: 27, status: 'available' }, { id: 28, status: 'available' }] },
          { roomNumber: '402', beds: [{ id: 29, status: 'available' }, { id: 30, status: 'occupied' }, { id: 31, status: 'occupied' }, { id: 32, status: 'available' }] },
        ]
      }
    }
  }
};

const BedOccupancy = () => {
  const [selectedHostel, setSelectedHostel] = useState('');
  const [selectedBlock, setSelectedBlock] = useState('');
  const navigate = useNavigate();

  const handleHostelChange = (event) => {
    setSelectedHostel(event.target.value);
    setSelectedBlock(''); // Reset block selection when hostel changes
  };

  const handleBlockChange = (event) => {
    setSelectedBlock(event.target.value);
  };

  // Get available blocks for selected hostel
  const getAvailableBlocks = () => {
    if (!selectedHostel) return [];
    return Object.keys(mockHostelData[selectedHostel]?.blocks || {});
  };

  // Get rooms for selected hostel and block
  const getDisplayedRooms = () => {
    if (!selectedHostel) return [];
    if (!selectedBlock) {
      // If no block is selected, show all rooms from all blocks
      const hostelBlocks = mockHostelData[selectedHostel].blocks;
      return Object.values(hostelBlocks).flatMap(block => block.rooms);
    }
    // Show rooms from selected block
    return mockHostelData[selectedHostel].blocks[selectedBlock].rooms;
  };

  return (
    <Box className="bed-occupancy-container">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          sx={{
            mb: 3,
            backgroundColor: '#029202',
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#029202' },
            fontSize: 'clamp(14px, 1.5vw, 14px)',
            borderRadius: '10px',
            padding: '8px 16px',
            textTransform: 'none',
          }}
        >
          Back
        </Button>

        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          sx={{
            mb: 3,
            backgroundColor: '#029202',
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#029202' },
            fontSize: 'clamp(14px, 1.5vw, 14px)',
            borderRadius: '10px',
            padding: '8px 16px',
            textTransform: 'none',
          }}
        >
          Export to PDF
        </Button>
      </div>

      <Typography variant="h6" className="page-title">Occupancy Statistics</Typography>
      
      <Box className="dropdown-group" sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <InputLabel sx={{ fontSize: '0.875rem', top: '-5px' }}>Hostel</InputLabel>
          <Select
            value={selectedHostel}
            onChange={handleHostelChange}
            label="Hostel"
            sx={{
              paddingTop: '4px',
              paddingBottom: '4px',
              fontSize: '0.875rem',
              height: '36px',
            }}
          >
            <MenuItem value=""><em>None</em></MenuItem>
            {Object.keys(mockHostelData).map((hostel) => (
              <MenuItem key={hostel} value={hostel}>{hostel}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <InputLabel sx={{ fontSize: '0.875rem', top: '-5px' }}>Block</InputLabel>
          <Select
            value={selectedBlock}
            onChange={handleBlockChange}
            label="Block"
            disabled={!selectedHostel}
            sx={{
              paddingTop: '4px',
              paddingBottom: '4px',
              fontSize: '0.75rem',
              height: '36px',
            }}
          >
            <MenuItem value=""><em>All Blocks</em></MenuItem>
            {getAvailableBlocks().map((block) => (
              <MenuItem key={block} value={block}>Block {block}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={2}>
        {getDisplayedRooms().map((room, index) => (
          <Grid item xs={12} md={6} key={room.roomNumber}>
            <Box className="room-container">
              <Typography variant="subtitle1" className="room-number">Room {room.roomNumber}</Typography>
              <Grid container spacing={1}>
                {room.beds.map((bed) => (
                  <Grid item xs={3} key={bed.id}>
                    <div className={`bed-icon ${bed.status}`} onClick={() => alert(`Bed ${bed.id} is ${bed.status}`)}>
                      {bed.id}
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BedOccupancy;