// /src/components/SharingProcedure.js
import React, { useState } from 'react';
import {
  Container,
  Button,
  TextField,
  MenuItem,
  Typography,
  Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png'; // Make sure the path to the logo is correct

const SharingProcedure = () => {
  // eslint-disable-next-line
  const [hostel, setHostel] = useState('');
  const [block, setBlock] = useState('');
  const [bed, setBed] = useState('');
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="sm"
      sx={{
        marginTop: '20px',
        padding: '20px',
        minHeight: '100vh',
        display: 'block',
      }}
    >
      <Button
        variant="contained"
        onClick={() => navigate(-1)}
        sx={{
          mb: 3,
          backgroundColor: '#029202',
          color: '#FFFFFF',
          '&:hover': { backgroundColor: '#029202' },
          fontSize: 'clamp(14px, 1.5vw, 14px)',
          borderRadius: '10px',
          padding: '8px 16px',
          textTransform: 'none',
        }}
      >
        Back
      </Button>

      <div style={{ textAlign: 'center', marginBottom: '15px' }}>
        <img src={logo} alt="Logo" style={{ width: '140px', height: '140px' }} />
      </div>

      <Typography
        variant="h6"
        sx={{
          textAlign: 'center',
          marginBottom: '15px',
          color: '#029202',
        }}
      >
        Sharing Procedure
      </Typography>

      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <TextField
            label="Hostel"
            select
            value={hostel}
            onChange={(e) => setHostel(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value="KingsA">Kings A</MenuItem>
            <MenuItem value="KingsB">Kings B</MenuItem>
            <MenuItem value="KingsC">Kings C</MenuItem>
            <MenuItem value="KingsD">Kings D</MenuItem>
          </TextField>
        </Grid> */}

        <Grid item xs={12}>
          <TextField
            label="Block"
            select
            value={block}
            onChange={(e) => setBlock(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value="Block1">B1</MenuItem>
            <MenuItem value="Block2">B2</MenuItem>
            <MenuItem value="Block3">B3</MenuItem>
            <MenuItem value="Block4">B4</MenuItem>
            <MenuItem value="Block5">B5</MenuItem>
            <MenuItem value="Block6">B6</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Bed"
            select
            value={bed}
            onChange={(e) => setBed(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <Button
            variant="contained"
            disabled={ !block || !bed}
            sx={{
              mb: 3,
              backgroundColor: '#029202',
              color: '#FFFFFF',
              '&:hover': { backgroundColor: '#029202' },
              fontSize: 'clamp(14px, 1.5vw, 14px)',
              borderRadius: '10px',
              padding: '8px 16px',
              textTransform: 'none',
              width: '100%',
            }}
          >
            Add Share
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            disabled={!block || !bed}
            sx={{
              mb: 3,
              backgroundColor: '#cf2727',
              color: '#FFFFFF',
              '&:hover': { backgroundColor: '#cf2727' },
              fontSize: 'clamp(14px, 1.5vw, 14px)',
              borderRadius: '10px',
              padding: '8px 16px',
              textTransform: 'none',
              width: '100%',
            }}
          >
            Del. Share
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SharingProcedure;
