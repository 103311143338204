import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, ButtonBase, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import AWS from 'aws-sdk';
import 'chart.js/auto';
import './AdminDashboard.css';

// AWS Configuration
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const dynamoDb = new AWS.DynamoDB();

const DEFAULT_DATA = {
  totalNoPresent: 0,
  checkedIn: 0,
  clergyPresent: 0,
  checkingOut: 0,
  checkedOut: 0,
  totalBedsOccupied: 0,
  totalNoBeds: 100, // Default total number of beds
  totalNoSharing: 0,
  willingToShare: 0,
};

// Function to fetch a single day's data from DynamoDB
const fetchDataFromDynamoDB = async (formattedDate) => {
  const params = {
    TableName: 'pm-cico-admin-table',
    Key: {
      dateToday: { S: formattedDate },
    },
  };

  try {
    const data = await dynamoDb.getItem(params).promise();
    return data.Item
      ? { ...DEFAULT_DATA, ...AWS.DynamoDB.Converter.unmarshall(data.Item) }
      : DEFAULT_DATA;
  } catch (error) {
    console.error('Error fetching data:', error);
    return DEFAULT_DATA;
  }
};

// Function to fetch the past week's data from DynamoDB
const fetchWeeklyDataFromDynamoDB = async () => {
  const today = new Date();
  const weekDataPromises = [];
  const labels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const weekData = new Array(7).fill(0);

  for (let i = 6; i >= 0; i--) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
    const dayIndex = date.getDay();

    weekDataPromises.push(
      fetchDataFromDynamoDB(formattedDate)
        .then((item) => {
          weekData[dayIndex] = item.totalNoPresent;
        })
        .catch(() => {
          weekData[dayIndex] = 0;
        })
    );
  }

  await Promise.all(weekDataPromises);
  return { weekData, labels };
};

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState(DEFAULT_DATA);
  const [weeklyAttendance, setWeeklyAttendance] = useState(new Array(7).fill(0));
  const [chartLabels, setChartLabels] = useState(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch today's data
      const today = new Date();
      const formattedToday = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;
      const dynamoData = await fetchDataFromDynamoDB(formattedToday);
      setData(dynamoData);

      // Fetch weekly data
      const { weekData, labels } = await fetchWeeklyDataFromDynamoDB();
      setWeeklyAttendance(weekData);
      setChartLabels(labels);
    };

    fetchData();
  }, []);

  const barData = {
    labels: chartLabels,
    datasets: [
      {
        label: 'People Present',
        data: weeklyAttendance,
        backgroundColor: '#029202',
        borderRadius: 4,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          display: true,
          autoSkip: false,
          maxRotation: 0,
          font: {
            size: 10,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: { display: false },
        ticks: {
          display: true,
          font: {
            size: 10,
          },
        },
        suggestedMax: Math.max(...weeklyAttendance) * 1.2,
      },
    },
  };

  const handleBedsOccupiedClick = () => navigate('/occupancy');
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleRegisterClick = () => {
    setAnchorEl(null);
    navigate('/adminregistration');
  };
  const handleExportClick = () => {
    setAnchorEl(null);
    // Add export functionality here
  };

  return (
    <div className="dashboard-container">
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }}>
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          sx={{
            backgroundColor: '#029202',
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#017501' },
            fontSize: 'clamp(14px, 1.5vw, 14px)',
            borderRadius: '12px',
            padding: '8px 16px',
            textTransform: 'none',
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleMenuOpen}
          sx={{
            backgroundColor: '#029202',
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#017501' },
            fontSize: 'clamp(14px, 1.5vw, 14px)',
            borderRadius: '12px',
            padding: '8px 16px',
            textTransform: 'none',
          }}
        >
          Options
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ '& .MuiPaper-root': { border: '1px solid #029202', color: '#029202', backgroundColor: '#f9fff9' } }}
        >
          <MenuItem onClick={handleRegisterClick} sx={{ color: '#029202' }}>Register Hostel</MenuItem>
          <MenuItem onClick={handleExportClick} sx={{ color: '#029202' }}>Export to PDF</MenuItem>
        </Menu>
      </div>

      <Typography variant="h6" className="title">People Metrics Dashboard</Typography>

      <Grid container spacing={2}>
        {[
          { title: 'Number Present', value: data.totalNoPresent },
          { title: 'Checked-In', value: data.checkedIn },
          { title: 'Clergy Present', value: data.clergyPresent },
          { title: 'Checking-Out', value: data.checkingOut, extraLabel: 'Checked-Out', extraValue: data.checkedOut },
          { title: 'Beds Occupied', value: data.totalBedsOccupied, extraLabel: 'Beds Unoccupied', extraValue: data.totalNoBeds - data.totalBedsOccupied, isInteractive: true },
          { title: 'Sharing', value: data.totalNoSharing, extraLabel: 'Willing to share', extraValue: data.willingToShare },
        ].map((metric, index) => (
          <Grid item xs={6} key={index}>
            {metric.isInteractive ? (
              <ButtonBase onClick={handleBedsOccupiedClick} sx={{ width: '100%', display: 'block', textAlign: 'inherit' }}>
                <div className="metric-card">
                  <div className="metric-content">
                    <div className="metric-title">{metric.title}</div>
                    <div className="metric-value">{metric.value}</div>
                    {metric.extraLabel && (
                      <>
                        <div className="metric-extra-label">{metric.extraLabel}</div>
                        <div className="metric-extra-value">{metric.extraValue}</div>
                      </>
                    )}
                  </div>
                </div>
              </ButtonBase>
            ) : (
              <div className="metric-card">
                <div className="metric-content">
                  <div className="metric-title">{metric.title}</div>
                  <div className="metric-value">{metric.value}</div>
                  {metric.extraLabel && (
                    <>
                      <div className="metric-extra-label">{metric.extraLabel}</div>
                      <div className="metric-extra-value">{metric.extraValue}</div>
                    </>
                  )}
                </div>
              </div>
            )}
          </Grid>
        ))}
      </Grid>

      <div className="chart-section">
        <Bar
          data={barData}
          options={barOptions}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
        <Typography className="chart-title">Weekly Attendance Overview</Typography>
      </div>
    </div>
  );
};

export default AdminDashboard;
