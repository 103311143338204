import React, { useState } from 'react';
import { Container, TextField, Button, List, ListItem, ListItemText, IconButton, Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import './ToDoList.css';

function ToDoList() {
  const [tasks, setTasks] = useState([]);
  const [taskInput, setTaskInput] = useState('');

  const handleAddTask = () => {
    if (taskInput.trim()) {
      setTasks([...tasks, { text: taskInput, id: Date.now(), completed: false }]);
      setTaskInput('');
    }
  };

  const handleCompleteTask = (id) => {
    setTasks(tasks.map((task) => 
      task.id === id ? { ...task, completed: !task.completed } : task
    ));
  };

  const navigate = useNavigate();

  return (
    <Container className="todo-container">
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          sx={{
            mb: 3,
            backgroundColor: '#029202',
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#029202' },
            fontSize: 'clamp(14px, 1.5vw, 14px)',
            borderRadius: '12px',
            padding: '8px 16px',
            textTransform: 'none',
          }}
        >
          Back
        </Button>
      </div>
      <Typography variant="h6" className="todo-title">Reminders & Notes</Typography>
      <Box className="todo-input-container">
        <TextField
          value={taskInput}
          onChange={(e) => setTaskInput(e.target.value)}
          placeholder="Add a new task..."
          variant="outlined"
          className="todo-input"
          color='#029202'
        />
        <Button 
          variant="contained" 
          onClick={handleAddTask} 
          className="add-task-button"
          sx={{ backgroundColor: '#029202', '&:hover': { backgroundColor: '#027a02' } }}
        >
          Add
        </Button>

      </Box>
      <List className="task-list">
        {tasks.map((task) => (
          <ListItem key={task.id} className={`task-item ${task.completed ? 'completed' : ''}`}>
            <ListItemText 
              primary={task.text} 
              className={`task-text ${task.completed ? 'task-text-completed' : ''}`} 
            />
            <IconButton 
              edge="end" 
              onClick={() => handleCompleteTask(task.id)} 
              className="complete-task-button"
            >
              <CheckIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default ToDoList;
